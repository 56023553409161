.fc-toolbar-title {
  color: #3366cc !important;
}

.fc-today-button {
  background-color: #3366cc !important;
  color: #fff !important;
  border-radius: 86px !important;
  padding: 10px 30px !important;
  gap: 10px !important;
  border: none !important;
}

.fc-next-button {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  color: #3366cc !important;
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.fc-icon .fc-icon-chevron-right {
  width: 15px !important;
  height: 15px !important;
  text-align: center !important;
}

.fc-prev-button {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  color: #3366cc !important;
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 7px !important;
}

.fc-event-title {
  font-size: 0.7rem !important;
}

.fc-theme-standard td {
  border: 4px solid #ffffff;
}

.fc .fc-daygrid-day-number {
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 17px !important;
  color: #ffffff;
}

.fc .fc-daygrid-day-top {
  display: flex !important;
  flex-direction: row !important;
  font-weight: 400 !important;
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}

.fc-day-other {
  opacity: 0.6 !important;
  background-color: #eaeaea !important;
}

div[edit="true"] .fc-daygrid-day-top {
  background-color: red !important;
}

/* fc-daygrid-day fc-day fc-day-mon fc-day-future */
/* fc-daygrid-day fc-day fc-day-mon fc-day-future fc-day-other */

.fc-event-title {
  margin-top: 30px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.fc-toolbar-title {
  font-family: "Raleway" !important;
  font-weight: 400 !important;
  font-size: 32px !important;
  color: #000000 !important;
}

.fc-toolbar-title::first-letter {
  text-transform: capitalize !important;
}

@media (max-width: 600px) {
  .fc-toolbar-title {
    font-size: 18px !important;
  }
}
