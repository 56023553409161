.swal2-popup {
  font-family: Raleway;
}
.swal2-title{
  font-family: Raleway;
}
.swal2-html-container{
  font-family: Raleway;
}
.swal2-styled{
  font-family: Raleway;
}
/* Telolet */

@font-face{
  font-family: Telolet;
  src: url(./assets/fonts/Telolet/OmTeloletOm.ttf) format('truetype')
};

/* Raleway */

@font-face{
  font-family: Raleway;
  src: url(./assets/fonts/Raleway/Raleway-Regular.ttf) format('truetype')
  };

@font-face{
font-family: RalewayMedium;
src: url(./assets/fonts/Raleway/Raleway-Medium.ttf) format('truetype')
};

@font-face{
  font-family: RalewayBold;
  src: url(./assets/fonts/Raleway/Raleway-Bold.ttf) format('truetype')
};


@font-face{
  font-family: RalewayMediumItalic;
  src: url(./assets/fonts/Raleway/Raleway-MediumItalic.ttf) format('truetype')
};

@font-face{
  font-family: RalewayItalicVariable;
  src: url(./assets/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf) format('truetype')
};

@font-face{
  font-family: RalewayVariable;
  src: url(./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf) format('truetype')
};

/* GOTHIC */
@font-face{
  font-family: Gothic;
  src: url(./assets/fonts/Gothic/_Trade\ Gothic\ LT.ttf) format('truetype')
};

@font-face{
  font-family: GothicOblique;
  src: url(./assets/fonts/Gothic/_Trade\ Gothic\ LT\ Oblique.ttf) format('truetype')
};

@font-face{
  font-family: GothicBold2;
  src: url(./assets/fonts/Gothic/_Trade\ Gothic\ LT\ Bold\ No.\ 2.ttf) format('truetype')
};

@font-face{
  font-family: GothicBold2Oblique;
  src: url(./assets/fonts/Gothic/_Trade\ Gothic\ LT\ Bold\ No.\ 2\ Oblique.ttf) format('truetype')
};

/* GOTHIC BOLD */

@font-face{
  font-family: BoldGothic;
  src: url(./assets/fonts/GothicBold/_Trade\ Gothic\ LT\ Bold.ttf) format('truetype')
};

@font-face{
  font-family: BoldGothicOblique;
  src: url(./assets/fonts/GothicBold/_Trade\ Gothic\ LT\ Bold\ Oblique.ttf) format('truetype')
};

/* GOTHIC CONDENSED EIGHTEEN */

@font-face{
  font-family: GothicCondensed18;
  src: url(./assets/fonts/GothicCondEighteen/_Trade\ Gothic\ LT\ Condensed\ No.\ 18.ttf) format('truetype')
};

@font-face{
  font-family: GothicCondensedOblique18;
  src: url(./assets/fonts/GothicCondEighteen/_Trade\ Gothic\ LT\ Condensed\ No.\ 18\ Oblique.ttf) format('truetype')
};

@font-face{
  font-family: BoldGothicCondensed20;
  src: url(./assets/fonts/GothicCondEighteen/_Trade\ Gothic\ LT\ Bold\ Condensed\ No.\ 20.ttf) format('truetype')
};

@font-face{
  font-family: BoldGothicCondensedOblique20;
  src: url(./assets/fonts/GothicCondEighteen/_Trade\ Gothic\ LT\ Bold\ Condensed\ No.\ 20\ Oblique.ttf) format('truetype')
};

/* Gothic Extended */

@font-face{
  font-family: GothicExtended;
  src: url(./assets/fonts/GothicExtended/_Trade\ Gothic\ LT\ Extended.ttf) format('truetype')
};

@font-face{
  font-family: GothicExtendedBold;
  src: url(./assets/fonts/GothicExtended/_Trade\ Gothic\ LT\ Bold\ Extended.ttf) format('truetype')
};

/* Gothic Light */

@font-face{
  font-family: GothicLight;
  src: url(./assets/fonts/GothicLight/_Trade\ Gothic\ LT\ Light.ttf) format('truetype')
};

@font-face{
  font-family: GothicLigthOblique;
  src: url(./assets/fonts/GothicLight/_Trade\ Gothic\ LT\ Light\ Oblique.ttf) format('truetype')
};

/* Gothic 18 */

@font-face{
  font-family: Gothic18Condensed;
  src: url(./assets/fonts/Gothic18/_TRADEGOTHICNO.18-CONDENSED.TTF) format('truetype')
};

@font-face{
  font-family: Gothic18CondensedOblique;
  src: url(./assets/fonts/Gothic18/_TRADEGOTHICNO.18-CONDOBL.TTF) format('truetype')
};

/* Gothic 20 */

@font-face{
  font-family: Gothic20CondensedBold;
  src: url(./assets/fonts/Gothic20/_trade-gothic-no-20-condensed-bold.ttf) format('truetype')
};

@font-face{
  font-family: Gothic20CondensedBoldOblique;
  src: url(./assets/fonts/Gothic20/_trade-gothic-no-20-condensed-bold-oblique.ttf) format('truetype')
};



